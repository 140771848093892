.wrapper-components {
    position: relative;
    height: calc(100vh - 55px);
    overflow: hidden;
}

.div-controls {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

footer {
    background: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
}

.footer-item-btn {
    flex: 0.2;
    text-align: center;
}

.footer-item-btn .btn {
    width: 90%;
}

.footer-item-btn label {
    display: block;
}
